<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <modal name="detail" :draggable="draggable" :clickToClose="false" height="auto" :width="modalWidth">
      <div class="modal-body">
        <History :mode="mode" :params="params" @close="hide"/>
      </div>
    </modal>
    <form method="#" action="#">
      <div class="row">
        <div class="col-sm-6">
        </div>
        <div class="col-sm-6">
          <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
        </div>
      </div>
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <div class="card-content" v-if="showSearchCondition">
          <!-- 期間 -->
          <div class="form-group search-condition-item" style="display: inline-block">
            <div><label>{{$t('label.term')}}</label></div>
            <vSelect name="search_year"
                      class="style-chooser length5"
                      style="display: inline-block;"
                      :clearable="false"
                      :options="years"
                      v-model="searchCond.search_year"
                      :disabled="isCurrentMonth">
              <div slot="no-options">{{$t('message.no_matching_options')}}</div>
            </vSelect>&nbsp;{{$t('label.year')}}&nbsp;
            <vSelect name="search_month"
                      class="style-chooser length3"
                      style="display: inline-block;"
                      :clearable="false"
                      :options="months"
                      v-model="searchCond.search_month"
                      :disabled="isCurrentMonth">
              <div slot="no-options">{{$t('message.no_matching_options')}}</div>
            </vSelect>&nbsp;{{$t('label.month')}}&nbsp;
          </div>
          <!-- 当月 -->
          <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
            <label for="isCurrentMonth">{{$t('label.this_month')}}</label>
            <div style="padding: 6px 0;">
              <input
                type="checkbox"
                v-model="isCurrentMonth"
                class="gray"
                id="isCurrentMonth"
                @click="isCurrentMonthClicked"
              >
            </div>
          </div>
          <!-- 勤務体系 -->
          <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
            <label>{{labels.workSchedule}}</label><br>
            <el-select class="select-success"
                        size="large"
                        placeholder=""
                        v-model="searchCond.work_schedule_code">
              <el-option v-for="work_schedule in this.comboData.work_schedule_list"
                          class="select-success"
                          :value="work_schedule.value"
                          :label="work_schedule.label"
                          :key="work_schedule.value">
              </el-option>
            </el-select>
          </div>
          <div class="search-button-area">
            <template v-if="labels.search === 1">
              <button type="button" class="btn btn-search btn-sm btn-default" @click="search">
                <span class="btn-label"><i class="ti-search"></i></span>
                {{$t('button.search')}}
              </button>
            </template>
          </div>
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]"/>
    <template v-if="tableData && tableData.length > 0">
      <div class="card">
        <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div>
        <div class="card-content">
          <button type="button" class="btn btn-warning btn-sm btn-default" @click="showAdd">
            {{$t('button.timecard_history')}}
          </button>
          <br>
          <div style="margin-top: 10px;">
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'" border
                      :data="tableData"
                      :key="componentKey"
                      :style="tableStyle"
                      ref="table"
                      :row-class-name="tableRowClassName">
              <!-- 労働日 -->
              <el-table-column v-if="showTargetDate" width="150" :label="labels.week_day_ryaku === undefined ? labels.weekDay_ryaku === undefined ? '' : labels.weekDay_ryaku : labels.week_day_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <template>
                      <div class="phase-header__title">{{scope.column.label}}</div>
                    </template>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: center; font-size: medium; font-weight: bold;">
                    {{props.row.target_date}}
                  </div>
                  <div style="text-align: center;">
                    <small>{{props.row.work_schedule_name}}</small>
                  </div>
                </template>
              </el-table-column>
              <!-- 出勤 -->
              <el-table-column v-if="showStampingStartTime" width="150" :label="labels.stampingType_JOB_START_ryaku === undefined ? labels.stampingTypeJOBSTART_ryaku === undefined ? '' : labels.stampingTypeJOBSTART_ryaku : labels.stampingType_JOB_START_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div :style="'text-align: center; font-size: medium; font-weight: bold; margin: 5px 0;' + (props.row.color===3 ? ' color: red;' : '')" :title="props.row.error_reason">
                    {{props.row.start_time}}
                  </div>
                  <div style="text-align: center;">
                    <img src="/static/img/destination.png" @click="createURL(props.row.start_lat, props.row.start_lng)" style="width: 25px; cursor: pointer; display: inline-block; vertical-align: bottom;" v-if="props.row.start_time && props.row.start_lat && props.row.start_lat !== 'None'"/>
                    &nbsp;
                    <small style="display: inline-block; vertical-align: bottom;">{{getLabel(getSelectName(props.row.start_time_entry_flg, comboData.entry_flg_list))}}</small>
                  </div>
                </template>
              </el-table-column>
              <!-- 退勤 -->
              <el-table-column v-if="showStampingEndTime" width="150" :label="labels.stampingType_JOB_FINISH_ryaku === undefined ? labels.stampingTypeJOBFINISH_ryaku === undefined ? '' : labels.stampingTypeJOBFINISH_ryaku : labels.stampingType_JOB_FINISH_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div :style="'text-align: center; font-size: medium; font-weight: bold; margin: 5px 0;' + (props.row.color===3 ? ' color: red;' : '')" :title="props.row.error_reason">
                    {{props.row.end_time}}
                  </div>
                  <div style="text-align: center;">
                    <img src="/static/img/destination.png" @click="createURL(props.row.end_lat, props.row.end_lng)" style="width: 25px; cursor: pointer; display: inline-block; vertical-align: bottom;" v-if="props.row.end_time && props.row.end_lat && props.row.end_lat !== 'None'"/>
                    &nbsp;
                    <small style="display: inline-block; vertical-align: bottom;">{{getLabel(getSelectName(props.row.end_time_entry_flg, comboData.entry_flg_list))}}</small>
                  </div>
                </template>
              </el-table-column>
              <!-- 休憩開始 -->
              <el-table-column v-if="showStartTime" width="150" :label="labels.stampingType_BREAKTIME_START_ryaku === undefined ? '' : labels.stampingType_BREAKTIME_START_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div v-for="break_time in props.row.break_time_list" :key="break_time.break_start_time">
                    <div :style="'text-align: center; font-size: medium; font-weight: bold; margin: 5px 0;' + (break_time.color===3 ? ' color: red;' : '')">
                      {{break_time.break_start_time}}
                    </div>
                    <div style="text-align: center;">
                      <img src="/static/img/destination.png" @click="createURL(break_time.break_time_start_lat, break_time.break_time_start_lng)" style="width: 25px; cursor: pointer; display: inline-block; vertical-align: bottom;" v-if="break_time.break_start_time && break_time.break_time_start_lat && break_time.break_time_start_lat !== 'None'"/>
                      &nbsp;
                      <small style="display: inline-block; vertical-align: bottom;">{{getLabel(getSelectName(break_time.break_time_start_time_entry_flg, comboData.entry_flg_list))}}</small>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <!-- 休憩終了 -->
              <el-table-column v-if="showEndTime" width="150" :label="labels.stampingType_BREAKTIME_FINISH_ryaku === undefined ? '' : labels.stampingType_BREAKTIME_FINISH_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div v-for="break_time in props.row.break_time_list" :key="break_time.break_start_time">
                    <div :style="'text-align: center; font-size: medium; font-weight: bold; margin: 5px 0;' + (break_time.color===3 ? ' color: red;' : '')">
                      {{break_time.break_end_time}}
                    </div>
                    <div style="text-align: center;">
                      <img src="/static/img/destination.png" @click="createURL(break_time.break_time_end_lat, break_time.break_time_end_lng)" style="width: 25px; cursor: pointer; display: inline-block; vertical-align: bottom;" v-if="break_time.break_end_time && break_time.break_time_end_lat && break_time.break_time_end_lat !== 'None'"/>
                      &nbsp;
                      <small style="display: inline-block; vertical-align: bottom;">{{getLabel(getSelectName(break_time.break_time_end_time_entry_flg, comboData.entry_flg_list))}}</small>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <!-- 気持ち -->
              <el-table-column v-if="showSmileMark" :width="getColumnWidth(tableData, labels.smile_mark_ryaku === undefined ? labels.smileMark_ryaku === undefined ? '' : labels.smileMark_ryaku : labels.smile_mark_ryaku, 'smile_mark')" :label="labels.smile_mark_ryaku === undefined ? labels.smileMark_ryaku === undefined ? '' : labels.smileMark_ryaku : labels.smile_mark_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left;">
                    <small>&nbsp;</small>
                  </div>
                  <div style="text-align: left;">
                    {{props.row.smile_mark}}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  import TimeCardMixin from '@/components/Dashboard/Views/Working/TimeCardMixin'

  export default {
    mixins: [TimeCardMixin]
  }
</script>
<style scoped>
  a {
    white-space: normal;
  }
</style>
