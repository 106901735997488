<template>
  <div class="form-group">
    <ValidationProvider ref="provider" v-slot="{ valid, errors }" :rules="rules" :name="itemLabel + '|' + new Date().getTime().toString(16)">
      <div>
        <div class="error-message-wrapper" v-show="!valid">
          <small class="text-danger">
            <!-- エラーメッセージは付加した乱数を削除して表示する。 -->
            {{ getMessage(errors[0]) }}
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <ValidationTextArea
              v-model="itemValue"
              item-name="supplement"
              max-length="200"
              classes="form-control length60 font20"
              :item-label="$t('label.csv_formula')"
              :placeholder="disabled ? '' : $t('placeholder.formula')"
              :disabled="disabled"
              :rows="3"
              @input="$emit('input', itemValue)"
            />
          </div>
        </div>
        <div class="col-md-7">
          <ValidationSearchSelect
            v-model="workingItem"
            item-name="working_item_all_list"
            classes="length30"
            :clearable="false"
            :item-label="labels.working_item"
            :options="comboData.working_item_all_list"
            :disabled="disabled"
            @change="workingItemAllListChanged"
          />
        </div>
        <div class="col-md-3" style="padding-top: 10px;">
          <div>
            <button type="button" class="btn-default btn-calc" :disabled="disabled" @click="insertFormula('7')">
              <span style="font-size: 16px;">７</span>
            </button>
            <button type="button" class="btn-default btn-calc" :disabled="disabled" @click="insertFormula('8')">
              <span style="font-size: 16px;">８</span>
            </button>
            <button type="button" class="btn-default btn-calc" :disabled="disabled" @click="insertFormula('9')">
              <span style="font-size: 16px;">９</span>
            </button>
            <button type="button" class="btn-default btn-calc" :disabled="disabled" @click="insertFormula('/')">
              <span style="font-size: 16px;">÷</span>
            </button>
          </div>
          <div style="margin-top: 2px;">
            <button type="button" class="btn-default btn-calc" :disabled="disabled" @click="insertFormula('4')">
              <span style="font-size: 16px;">４</span>
            </button>
            <button type="button" class="btn-default btn-calc" :disabled="disabled" @click="insertFormula('5')">
              <span style="font-size: 16px;">５</span>
            </button>
            <button type="button" class="btn-default btn-calc" :disabled="disabled" @click="insertFormula('6')">
              <span style="font-size: 16px;">６</span>
            </button>
            <button type="button" class="btn-default btn-calc" :disabled="disabled" @click="insertFormula('*')">
              <span style="font-size: 16px;">×</span>
            </button>
          </div>
          <div style="margin-top: 2px;">
            <button type="button" class="btn-default btn-calc" :disabled="disabled" @click="insertFormula('1')">
              <span style="font-size: 16px;">１</span>
            </button>
            <button type="button" class="btn-default btn-calc" :disabled="disabled" @click="insertFormula('2')">
              <span style="font-size: 16px;">２</span>
            </button>
            <button type="button" class="btn-default btn-calc" :disabled="disabled" @click="insertFormula('3')">
              <span style="font-size: 16px;">３</span>
            </button>
            <button type="button" class="btn-default btn-calc" :disabled="disabled" @click="insertFormula('-')">
              <span style="font-size: 16px;">ー</span>
            </button>
          </div>
          <div style="margin-top: 2px;">
            <button type="button" class="btn-default btn-calc" :disabled="disabled" @click="insertFormula('0')">
              <span style="font-size: 16px;">０</span>
            </button>
            <button type="button" class="btn-default btn-calc" :disabled="disabled" @click="insertFormula('00')">
              <span style="font-size: 16px;">00</span>
            </button>
            <button type="button" class="btn-default btn-calc" :disabled="disabled" @click="insertFormula('.')">
              <span style="font-size: 16px;">．</span>
            </button>
            <button type="button" class="btn-default btn-calc" :disabled="disabled" @click="insertFormula('+')">
              <span style="font-size: 16px;">＋</span>
            </button>
          </div>
          <div style="margin-top: 2px;">
            <button type="button" class="btn-default btn-calc" :disabled="disabled" @click="insertFormula('(')">
              <span style="font-size: 16px;">（</span>
            </button>
            <button type="button" class="btn-default btn-calc" :disabled="disabled" @click="insertFormula(')')">
              <span style="font-size: 16px;">）</span>
            </button>
            <button type="button" class="btn-default btn-calc2" :disabled="disabled" @click="deleteFormula()">
              <span style="font-size: 16px;">DEL</span>
            </button>
          </div>
        </div>
        <div class="col-md-2">
        </div>
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
import ValidationSearchSelect from '@/components/UIComponents/Inputs/ValidationSearchSelect'
import ValidationTextArea from '@/components/UIComponents/Inputs/ValidationTextArea'
import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ValidationSearchSelect,
      ValidationTextArea
    },
    props: {
      value: String,
      rules: String,
      itemLabel: String,
      comboData: Object,
      disabled: Boolean,
      labels: Object
    },
    data () {
      return {
        itemValue: null,
        workingItem: null
      }
    },
    watch: {
      value () {
        this.itemValue = this.value
      }
    },
    mounted () {
      this.itemValue = this.value
    },
    methods: {
      insertFormula (sign) {
        this.itemValue = this.itemValue ? this.itemValue += sign : sign
        this.$emit('input', this.itemValue)
      },
      deleteFormula () {
        this.itemValue = this.itemValue ? this.itemValue.substring(0, this.itemValue.length - 1) : ''
        this.$emit('input', this.itemValue)
      },
      workingItemAllListChanged () {
        for (const row of this.comboData.working_item_all_list) {
          if (row.value === this.workingItem) {
            this.itemValue = this.itemValue ? this.itemValue + ' [' + row.label + ']' : '[' + row.label + ']'
            this.$emit('input', this.itemValue)
            break
          }
        }
      },
      getMessage (message) {
        if (message === null || message === undefined) {
          return ''
        } else if (message.indexOf('|') === -1) {
          return message
        } else {
          return message.replace(message.substring(message.indexOf('|'), message.indexOf('|') + 12), '')
        }
      }
    }
  }
</script>

<style scoped>
</style>