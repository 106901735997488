<template>
  <div class="form-group">
    <ValidationProvider ref="provider" v-slot="{ valid, errors }" :rules="rules" :name="itemLabel1 + '|' + new Date().getTime().toString(16)">
      <div>
        <div class="error-message-wrapper" v-show="!valid">
          <small class="text-danger">
            <!-- エラーメッセージは付加した乱数を削除して表示する。 -->
            {{ getMessage(errors[0]) }}
          </small>
        </div>
      </div>
      <div style="width: 50%; display: inline-block; margin-bottom: 5px;">
        <button type="button" class="btn btn-sm btn-default btn-warning" @click="$emit('expand')" v-show="!expand">{{ $t('button.expand_table') }}</button>
        <button type="button" class="btn btn-sm btn-default btn-warning" @click="$emit('expand')" v-show="expand">{{ $t('button.shrink_table') }}</button>
      </div>
      <div style="width: 49%; display: inline-block; text-align: right;">
        {{ $t('label.csv_template_draggable') }}
      </div>
      <el-table-draggable>
        <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"        border
          style="width: 838px;"
          :max-height="maxHeight"
          :data="tableData"
          :empty-text="$t('label.no_data')">
          <!-- 有効 -->
          <el-table-column
            width="50"
            align="center">
            <template slot="header" slot-scope="scope"> 
              <div class="phase-header tableHeader">
                {{ $t('label.csv_enabled') }}
                <br>
                <input type="checkbox" v-model="checkAll" class="gray" :disabled="disabled" @change="checkAllChanged" />
              </div>
            </template>
            <template slot-scope="props">
              <div style="text-align: center;">
                <input type="checkbox" v-model="props.row.enabled" class="gray" :disabled="disabled" />
              </div>
            </template>
          </el-table-column>
          <!-- 勤怠項目 -->
          <el-table-column
            width="260"
            :label="itemLabel1 ? itemLabel1 : ''"
            align="center">
            <template slot-scope="props">
              <div style="text-align: left;">
                {{props.row.working_item_name}}
              </div>
            </template>
          </el-table-column>
          <!-- 単位 -->
          <el-table-column
            width="230"
            :label="itemLabel2 ? itemLabel2 : ''"
            align="center">
            <template slot-scope="props">
              <div style="text-align: left;">
                <template v-if="props.row.working_item_unit">
                  <el-select class="select-success"
                              size="large"
                              :disabled="disabled"
                              v-model="props.row.working_item_unit">
                    <el-option v-for="option in props.row.working_item_unit_list"
                      class="select-success"
                      :value="option.format_serial_number"
                      :label="option.label"
                      :key="option.format_serial_number">
                    </el-option>
                  </el-select>
                </template>
              </div>
            </template>
          </el-table-column>
          <!-- 囲み有無 -->
          <el-table-column
            width="130"
            :label="itemLabel3 ? itemLabel3 : ''"
            align="center">
            <template slot-scope="props">
              <div style="text-align: left;">
                <el-select class="select-success"
                            size="large"
                            :disabled="disabled"
                            v-model="props.row.is_quotation">
                  <el-option v-for="option in props.row.is_quotation_list"
                    class="select-success"
                    :value="option.value"
                    :label="option.label"
                    :key="option.value">
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <!-- ゼロサプレス有無 -->
          <el-table-column
            width="150"
            :label="itemLabel4 ? itemLabel4 : ''"
            align="center">
            <template slot-scope="props">
              <div style="text-align: left;" v-if="props.row.working_item_unit ? props.row.working_item_unit_list.find((row) => row.format_serial_number === props.row.working_item_unit).is_zero_suppress : false">
                <el-select class="select-success"
                            size="large"
                            :disabled="disabled"
                            v-model="props.row.is_zero_suppress">
                  <el-option v-for="option in props.row.is_quotation_list"
                    class="select-success"
                    :value="option.value"
                    :label="option.label"
                    :key="option.value">
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-table-draggable>
    </ValidationProvider>
  </div>
</template>

<script>
  import ElTableDraggable from 'element-ui-el-table-draggable'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    components: {
      ElTableDraggable
    },
    props: {
      value: Array,
      options: Array,
      rules: String,
      itemLabel1: String,
      itemLabel2: String,
      itemLabel3: String,
      itemLabel4: String,
      disabled: Boolean,
      expand: Boolean
    },
    computed: {
      maxHeight () {
        return this.expand ? window.innerHeight - 250 : window.innerHeight - 680
      }
    },
    data () {
      return {
        tableData: [],
        checkAll: false
      }
    },
    watch: {
      value () {
        this.tableData = this.value
      }
    },
    mounted () {
      this.tableData = this.value
    },
    methods: {
      checkAllChanged () {
        for (const row of this.tableData) {
          row.enabled = this.checkAll
        }
        this.tableData = [...this.tableData]
        console.log(this.tableData)
      },
      getMessage (message) {
        if (message === null || message === undefined) {
          return ''
        } else if (message.indexOf('|') === -1) {
          return message
        } else {
          return message.replace(message.substring(message.indexOf('|'), message.indexOf('|') + 12), '')
        }
      }
    }
  }
</script>

<style scoped>
</style>